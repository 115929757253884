import swal from 'sweetalert2';

export function doSwalError(message) {
    swal({
        type: 'error',
        title: message,
        showConfirmButton: false,
        timer: 2500
    });
}

export function doSwalConfirmDelete() {
    return swal({
        title: 'Supprimer',
        text: 'Voulez-vous vraiment supprimer cet élément ? ',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Accepter',
        cancelButtonText: 'Annuler'
    })
}

export function doSwalConfirmNavigate(generate: boolean = false) {
    let text;
    if (generate) {
        text = 'Attention, les réponses ont été générées aléatoirement ! Voulez-vous consulter les graphiques de réponses ?'
    } else {
        text = 'Voulez-vous consulter les graphiques de réponses ?'
    }
    return swal({
        title: 'Réponses',
        text: text,
        type: 'success',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Accepter',
        cancelButtonText: 'Annuler'
    })
}

export function doSwalSuccess(message) {
    swal({
        type: 'success',
        title: message,
        showConfirmButton: false,
        timer: 2500
    });
}

export function doSwalConfirmDeleteSpeaker() {
    return swal({
        title: 'Supprimer',
        text: 'Voulez-vous vraiment supprimer cet intervenant ? ' +
            'ATTENTION : supprimer un intervenant supprimera aussi les intervalles qui lui sont atribués.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Accepter',
        cancelButtonText: 'Annuler'
    })
}

export function doSwalConfirmImportOldMediascopie() {
    return swal({
        title: 'Importer',
        html: '<p>Voulez-vous vraiment importer les anciennes mediascopies ?</p>' +
            '<b>ATTENTION :</b> cette opération peut prendre du temps, placer les données dans le dossier .../uploads/old.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Accepter',
        cancelButtonText: 'Annuler'
    })
}
