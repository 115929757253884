import {Routes, RouterModule} from '@angular/router';

// Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
    {
        path: 'user',
        loadChildren: () => import('../../user/user.module').then(m => m.UserModule)
    },
    {
        path: 'mediascopies',
        loadChildren: () => import('../../mediascopie/mediascopie.module').then(m => m.MediascopieModule)
    },
    {
        path: 'group',
        loadChildren: () => import('../../group/group.module').then(m => m.GroupModule)
    }
];
