import { Component, OnInit } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { RouteInfo } from "./sidebar.metadata";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './../auth/auth.service';

declare var $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];

    constructor(private router: Router, private authService: AuthService,
        private route: ActivatedRoute, public translate: TranslateService) {

    }

    ngOnInit() {
        $.getScript('./assets/js/app-sidebar.js');
        let role = localStorage.getItem('role');
        this.menuItems = ROUTES.filter(menuItem => menuItem.role == role);
    }

    Logout() {
        this.authService.logout();
        this.router.navigate(['/login']);
    }

    //NGX Wizard - skip url change
    ngxWizardFunction(path: string) {
        if (path.indexOf('forms/ngx') !== -1)
            this.router.navigate(['forms/ngx/wizard'], { skipLocationChange: false });
    }
}
