import { Router } from '@angular/router';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Observable } from 'rxjs';
import { doSwalError, doSwalSuccess } from '../../services/swal.services';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthService {

  token: string;
  isRefreshingToken: boolean = false;
  refreshTokenObservable;
  constructor(
    public http: HttpClient,
    public router: Router,
    private translate: TranslateService
  ) { }

  /**
   * Login user
   * @param {string} pseudo
   * @param {string} password
   * @param {string} redirect
   * @return {Void}
   */
  signinUser(pseudo: string, password: string, redirect?: string): void {
    // Data
    let params = new HttpParams();
    params = params.append('client_id', environment.client_id);
    params = params.append('client_secret', environment.client_secret);
    params = params.append('grant_type', 'password');
    params = params.append('username', pseudo);
    params = params.append('password', password);

    // Headers
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    // Request
    this.http.post(`${environment.api_url}/oauth2/token`, params, {
      headers: headers,
      params: params
    })
      .subscribe(
        data => {
          //save token
          localStorage.setItem('access_token', data["access_token"]);
          localStorage.setItem('refresh_token', data["refresh_token"]);

          //then we call the /me to grab some usefull infos about the currently logged user
          this.http.get(`${environment.api_url}/me`).subscribe(
            data_me => {
              localStorage.setItem('role', data_me["role"]);
              localStorage.setItem('user_fullname', data_me["firstname"] + ' ' + data_me["lastname"]);
              localStorage.setItem('user_lastConnection', data_me["last_connection"]);
              localStorage.setItem('user_avatar', (data_me["avatar"]) ? (data_me["avatar"]) : null);
              // Redirect
              if (redirect) {
                if (0 > redirect.indexOf('/?/')) {
                  redirect += '&token=' + this.getToken();
                } else {
                  redirect += '?token=' + this.getToken();
                }
                window.location.href = redirect;
              } else {
                this.router.navigateByUrl('/mediascopies/list');
              }
              doSwalSuccess('Identification réussie');
            },
            error_me => {
              console.log(error_me);
            }
          )
        },
        error => {
          let messageError = error.message;
          if (400 == error.status) {
            if (error && error.error && error.error.error && error.error.error == 'invalid_grant') {
              messageError = 'login.invalid_grant';
            }
            else if (error.error.errors && Array.isArray(error.error.errors)) {
              let errorMessage = [];
              error.error.errors.forEach((e) => {
                errorMessage.push(e.message);
              });
              messageError = errorMessage.join('<br />');
            }
          } else if (404 == error.status) {
            if (error.error) {
              messageError = error.error.message;
            }
          }
          doSwalError(this.translate.instant(messageError));
        }
      );
  }
  /**
   * Refresh Token
   * @return {Observable}
   */
  refreshToken(): any {
    let that = this;
    if (!this.isRefreshingToken) {
      // Disabled mutiple request for refresh
      this.isRefreshingToken = true;

      // Data
      let params = new HttpParams();
      params = params.append('client_id', environment.client_id);
      params = params.append('client_secret', environment.client_secret);
      params = params.append('grant_type', 'refresh_token');
      params = params.append('refresh_token', this.getRefreshToken());

      // Headers
      let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

      // Request
      this.refreshTokenObservable = Observable.create(observer => {
        this.http.post(`${environment.api_url}/oauth2/refresh-token`, params, {
          headers: headers,
          params: params
        }).subscribe(
          data => {
            // Save token
            localStorage.setItem('access_token', data["access_token"]);
            localStorage.setItem('refresh_token', data["refresh_token"]);

            // Complete observable
            observer.next(data);
            observer.complete();
            that.isRefreshingToken = false;
            that.refreshTokenObservable = false;
          },
          () => {
            // To much time without login all is expired
            this.logout();
            this.router.navigateByUrl('/login');
          }
        )
      }).share();
    }
    return this.refreshTokenObservable;
  }

  resetPassword(email) {
    return this.http.post(`${environment.api_url}/reset-password`, {email: email});
  }

  logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('role');
  }

  getToken(): string {
    return localStorage.getItem('access_token');
  }

  getRefreshToken() {
    return localStorage.getItem('refresh_token');
  }

  getRole() {
    return localStorage.getItem('role');
  }

  isAuthenticated(): boolean {
    console.log(this.getToken() !== null);
    return this.getToken() !== null;
  }

}
