import {RouteInfo} from './sidebar.metadata';

// Sidebar menu Routes and data
export const ROUTES: any[] = [
    // ANALYST MENU
    {
        path: '/mediascopies/list',
        title: 'Projets',
        icon: 'ft-file-text',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        role: 'analyst'
    },
    {
        path: '/user/profile',
        title: 'Mon compte',
        icon: 'ft-user',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        role: 'analyst'
    },
    // ADMIN MENU
    {
        path: '/mediascopies/list',
        title: 'Projets',
        icon: 'ft-file-text',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        role: 'admin'
    },
    {
        path: '/user/list',
        title: 'Utilisateurs',
        icon: 'ft-users',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        role: 'admin'
    },
    {
        path: '/user/profile',
        title: 'Mon compte',
        icon: 'ft-user',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        role: 'admin'
    }
];
